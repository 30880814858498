import "./collectionTab.scss";

import { Collection } from "~models/collection";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Item } from "~models/item";
import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { createListComponent, DOMHelper, IListComponent, Keys, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";
import { PlaylistProgramView } from "~views/playlistProgramView";
import { PlaylistVideoView } from "~views/playlistVideoView";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { checkImage } from "../../tools/tools";
import { onSelectTile } from "../rootPage";

const getCollectionLogoSrc = (source: Collection): string => {
  return source.getLogoImgUrl();
};

class CollectionDesc extends View {
  divImage: HTMLElement;
  images: { focusOn: string; focusOff: string };
  constructor(source: Collection) {
    super(DOMHelper.createDivWithParent(null, "CollectionDesc", "", ""));
    DOMHelper.createDivWithParent(this.rootElement, "", "subtitle", source.extras.subtitle);
    DOMHelper.createDivWithParent(this.rootElement, "", "summary", source.summary);

    this.images = {
      focusOn: require("~images/buttonCollection/showFocused.png"),
      focusOff: require("~images/buttonCollection/showUnfocused.png"),
    };
    const showbuttonElt = DOMHelper.createDivWithParent(this.rootElement, "ShowButton", "", "voir la collection");
    this.divImage = DOMHelper.createDivImg(showbuttonElt, "", "icon", this.images.focusOff);
    const collectionDescLogo = DOMHelper.createDivWithParent(this.rootElement, "CollectionDescLogo", null);
    collectionDescLogo.innerText = source.title;
    const collectionLogoSrc = getCollectionLogoSrc(source);
    checkImage(
      collectionLogoSrc,
      () => {
        collectionDescLogo.innerText = "";
        collectionDescLogo.style.backgroundImage = `url("${collectionLogoSrc}")`;
      },
      () => {}
    );
  }

  onFocused() {
    this.divImage.style.background = `url(${this.images.focusOn})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }

  onUnfocused() {
    this.divImage.style.background = `url(${this.images.focusOff})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }
}

class CollectionProgramsSwimlane extends View {
  constructor(source: ItemCollection, collectionTitle: string) {
    super(DOMHelper.createDivWithParent(null, "CollectionPrograms"));
    DOMHelper.createDivWithParent(this.rootElement, "", "title", source.title);
    const collectionProgramsLogo = DOMHelper.createDivWithParent(this.rootElement, null, "logo", null);
    collectionProgramsLogo.innerText = collectionTitle;
    const collectionLogoSrc = getCollectionLogoSrc(source);
    checkImage(
      collectionLogoSrc,
      () => {
        collectionProgramsLogo.innerText = "";
        collectionProgramsLogo.style.backgroundImage = `url("${collectionLogoSrc}")`;
      },
      () => {}
    );
    this.delegate = createListComponent({
      rootElement: DOMHelper.createDivWithParent(this.rootElement, "", "list"),
      modelSource: new StaticModelSource(source.items as Program[]),
      viewFactory: (model: any) => {
        return new PlaylistProgramView(model);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      spatialFocus: false,
      onSelect: onSelectTile,
    });
  }
}

class CollectionVideosWall extends View {
  private _list: IListComponent;
  private _scrollIndexUnregister?: () => void;
  constructor(source: ItemCollection, collectionTitle: string) {
    super(DOMHelper.createDivWithParent(null, "CollectionVideos"));
    DOMHelper.createDivWithParent(this.rootElement, "", "title", source.title);
    const collectionVideoLogo = DOMHelper.createDivWithParent(this.rootElement, null, "logo", null);
    collectionVideoLogo.innerText = collectionTitle;
    const collectionLogoSrc = getCollectionLogoSrc(source);
    checkImage(
      collectionLogoSrc,
      () => {
        collectionVideoLogo.innerText = "";
        collectionVideoLogo.style.backgroundImage = `url("${collectionLogoSrc}")`;
      },
      () => {}
    );

    this.delegate = this._list = createListComponent({
      rootElement: DOMHelper.createDivWithParent(this.rootElement, "", "wall"),
      modelSource: new StaticModelSource(source.items as (Integrale | Unit | Extrait)[]),
      viewFactory: model => {
        return new PlaylistVideoView(model, true);
      },
      horizontal: false,
      pageSize: 1,
      visibleBefore: 1,
      visibleAfter: 1,
      crossSectionWidth: 4,
      spatialFocus: false,
      mouseFocusInPageOnly: true,
      onSelect: onSelectTile,
    });
    this._scrollIndexUnregister = this._list.scrollIndex$.didChange(newIndex => {
      if (newIndex !== undefined && newIndex > 0) {
        DOMHelper.addClass(this.rootElement, "noDesc");
      } else {
        DOMHelper.removeClass(this.rootElement, "noDesc");
      }
    });
  }

  onRelease = () => {
    this._scrollIndexUnregister?.();
  };
}

export class CollectionTab extends View {
  private _list?: IListComponent;
  private _scrollIndexUnregister?: () => void;
  private _source: Collection;
  private _collectionItems?: Item[];
  private _background?: DynamicBackground;
  /**
   * Source has been fetched.
   * Meaning that source data is not partial.
   */
  private _isSourceReady = false;

  constructor(source: Collection) {
    super(DOMHelper.createDivWithParent(null, "CollectionTab"));
    this._source = source;
    //this._background =
    this._fetchSource();
  }

  onShown() {
    if (this._isSourceReady) {
      this._sendAnalyticsData();
    }
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[DETAILED COLLECTION] Source !", this._source);
          console.log("[DETAILED COLLECTION] Next !", value);

          this._source = value[0];

          console.log("[DETAILED COLLECTION] Source after !", this._source);

          this._collectionItems = value && value.length ? value[0].items : undefined;

          this._onSourceReady();
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[DETAILED COLLECTION] Error !", error);
        },
        () => {
          console.log("[DETAILED COLLECTION] Complete !");
        }
      );
  }

  private _onSourceReady() {
    this._isSourceReady = true;
    this._sendAnalyticsData();

    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      source: this._source.getBackgroundImgUrl(),
    });
    const source: (Collection | ItemCollection)[] = [this._source];
    const programs = new ItemCollection(
      "playlist_program",
      "playlist_program",
      "Les programmes de la collection",
      "",
      [],
      this._source.artworks,
      null,
      false
    );
    const videos = new ItemCollection(
      "wall_video",
      "wall_video",
      "Les vidéos de la collection",
      "",
      [],
      this._source.artworks,
      null,
      false
    );
    // const collectionDesc = new ItemCollection(
    //   "collectionDesc",
    //   "collectionDesc",
    //   "",
    //   "",
    //   [],
    //   this._collectionModel.artworks,
    //   null
    // );
    if (this._collectionItems && this._collectionItems.length) {
      this._collectionItems.forEach(item => {
        if (item instanceof Program) {
          item.itemCollection = programs;
          programs.items.push(item);
        }
        if (item instanceof Unit || item instanceof Integrale || item instanceof Extrait) {
          item.itemCollection = videos;
          videos.items.push(item);
        }
      });
    }

    if (programs.items.length) {
      source.push(programs);
    }

    if (videos.items.length) {
      source.push(videos);
    }

    this.delegate = this._list = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "CollectionVerticalList"),
        modelSource: new StaticModelSource(source),
        horizontal: false,
        viewFactory: model => {
          if (model instanceof ItemCollection && model.type === "playlist_program") {
            return new CollectionProgramsSwimlane(model, this._source.title);
          }
          if (model instanceof ItemCollection && model.type === "wall_video") {
            return new CollectionVideosWall(model, this._source.title);
          }
          return new CollectionDesc(model);
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleBefore: 0,
        visibleAfter: 1,
        spatialFocus: false,
        mouseFocusInPageOnly: true,
        onSelect: button => {
          if (button.type != "playlist_program" && button.type != "wall_video" && this.delegate?.onNav)
            this.delegate?.onNav(Keys.down);
          return true;
        },
      },
      list => {
        const defaultIndex = 0;
        list.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(this._list?.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );

    this._scrollIndexUnregister = this._list.scrollIndex$.didChange((newIndex, oldIndex) => {
      const newView = this._list?.viewFromIndex(newIndex);
      DOMHelper.addClass(newView?.rootElement, "onTop");
      DOMHelper.removeClass(this._list?.viewFromIndex(oldIndex)?.rootElement, "onTop");

      if (newView?.rootElement.id === "CollectionDesc") {
        this._background?.setOverlay(DynamicBackgroundOverlayType.gradient);
      } else {
        this._background?.setOverlay(DynamicBackgroundOverlayType.blur);
      }

      if (newIndex !== undefined) {
        this._list?.setFocusOnIndex(newIndex);
      }
    });
  }

  private _sendAnalyticsData = () => {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
  };

  onRelease = () => {
    this._scrollIndexUnregister?.();
  };
}
